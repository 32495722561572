<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card es-azure">
                <div class="card-header">
                    <div class="basic-form" style="width:100%;">
                        <!-- Tabs -->
                        <div class="form-row mb-2">
                            <div class="row mb-2">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item ponteiro">
                                        <a class="nav-link" :class="{'active': step==1}" @click="changeStep(1)">{{$t('chamadoProgramado.chamado')}}</a>
                                    </li>
                                    <li class="nav-item ponteiro">
                                        <a class="nav-link" :class="{'active': step==2}" @click="changeStep(2)">{{$t('chamadoProgramado.programacao')}}</a>
                                    </li>
                                    <li class="nav-item ponteiro">
                                        <a class="nav-link" :class="{'active': step==3}" @click="changeStep(3)">{{$t('chamadoProgramado.checklist')}}</a>
                                    </li>
                                    <li class="nav-item ponteiro">
                                        <a class="nav-link" :class="{'active': step==4}" @click="changeStep(4)">{{$t('chamadoProgramado.equipamentos')}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Chamado -->
                        <div v-if="step==1" class="form-row">
                            <div class="form-group col-md-12">
                                <label><strong>{{$t('global.descricao')}}</strong></label>
                                <input v-model="chamadoProgramado.textohotlinesolicitacoes" 
                                    type="text" class="form-control" placeholder="Descreva sua solicitação"
                                    :id="`textohotlinesolicitacoes_${chamadoProgramado.idchamadoprogramado}`">
                            </div>
                            <div class="form-group col-md-4">
                                <label><strong>{{$t('sols.uh')}}</strong></label>
                                <input v-model="chamadoProgramado.coduh"
                                    type="text" class="form-control" :placeholder="$t('sols.uh')"
                                    :id="`coduh_${chamadoProgramado.idchamadoprogramado}`">
                            </div>
                            <div class="form-group col-md-4">
                                <label><strong>Tipo:</strong></label>
                                <select class="form-control" v-model="chamadoProgramado.idtiposolicitacao"
                                    @change="loadTipoSolicitacaoItens(chamadoProgramado.idtiposolicitacao)"
                                    :id="`idtiposolicitacao_${chamadoProgramado.idchamadoprogramado}`">
                                    <template v-if="tipossolicitacao && tipossolicitacao.length > 0">
                                        <option v-for="tiposolicitacao in tipossolicitacao" v-bind:key="tiposolicitacao.idtiposolicitacao"
                                            :value="tiposolicitacao.idtiposolicitacao"
                                            :selected="chamadoProgramado.idtiposolicitacao==tiposolicitacao.idtiposolicitacao">{{tiposolicitacao.titulo}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-4">
                                <label><strong>Ítem:</strong></label>
                                <select class="form-control" v-model="chamadoProgramado.idtiposolicitacaoitem"
                                    :id="`idtiposolicitacaoitem_${chamadoProgramado.idchamadoprogramado}`">
                                    <template v-if="tiposolicitacaoItens && tiposolicitacaoItens.length > 0">
                                        <option v-for="tiposolicitacaoitem in tiposolicitacaoItens" v-bind:key="tiposolicitacaoitem.idtiposolicitacaoitem"
                                            :value="tiposolicitacaoitem.idtiposolicitacaoitem"
                                            :selected="chamadoProgramado.idtiposolicitacaoitem==tiposolicitacaoitem.idtiposolicitacaoitem">{{tiposolicitacaoitem.titulo}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label><strong>{{$t('sols.prioridade')}}</strong></label>
                                <select class="form-control" v-model="chamadoProgramado.idprioridade"
                                    :id="`idprioridade_${chamadoProgramado.idchamadoprogramado}`">
                                    <template v-if="prioridades && prioridades.length > 0">
                                        <option v-for="prioridade in prioridades" v-bind:key="prioridade.idprioridade"
                                            :value="prioridade.idprioridade"
                                            :selected="chamadoProgramado.idprioridade==prioridade.idprioridade">{{prioridade.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label><strong>{{$t('sols.responsavel')}}</strong></label>
                                <select class="form-control" v-model="chamadoProgramado.idresponsavel"
                                    :id="`idresponsavel_${chamadoProgramado.idchamadoprogramado}`">
                                    <template v-if="responsaveis && responsaveis.length > 0">
                                        <option v-for="responsavel in responsaveis" v-bind:key="responsavel.idusuario"
                                            :value="responsavel.idusuario"
                                            :selected="chamadoProgramado.idresponsavel==responsavel.idusuario">{{responsavel.nome}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                    <label class="negrito">Setor:</label>
                                    <select class="form-control" v-model="chamadoProgramado.idhotlinesetores"
                                        >
                                        <template v-if="setores && setores.length > 0">
                                            <option v-for="setor in setores" v-bind:key="setor.idhotlinesetores"
                                                :value="setor.idhotlinesetores"
                                                :selected="chamadoProgramado.idhotlinesetores==setor.idhotlinesetores">{{setor.deschotlinesetor}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            <div class="form-group col-md-3">
                                <label><strong>{{$t('sols.localatendimento')}}</strong></label>
                                <select class="form-control" v-model="chamadoProgramado.idlocalatendimento"
                                    :id="`idlocalatendimento_${chamadoProgramado.idchamadoprogramado}`">
                                    <template v-if="locaisAtendimento && locaisAtendimento.length > 0">
                                        <option v-for="local in locaisAtendimento" v-bind:key="local.idlocalatendimento"
                                            :value="local.idlocalatendimento"
                                            :selected="chamadoProgramado.idlocalatendimento==local.idlocalatendimento">{{local.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-4">
                                <div class="custom-control custom-switch pl-0">
                                    <strong><label class="pr-2 ponteiro" :for="`item-switch-${chamadoProgramado.idchamadoprogramado}`" >
                                        {{$t('sols.manutencaoexterna')}}</label></strong>
                                    <input  type="checkbox" name="manutencaoexterna" class="ponteiro" 
                                        :id="`item-switch-${chamadoProgramado.idchamadoprogramado}`"  
                                        v-model="chamadoProgramado.manutencaoexterna"
                                    >
                                </div> 
                                <select v-if="chamadoProgramado.manutencaoexterna" class="form-control" v-model="chamadoProgramado.fornecedor">
                                    <template v-if="fornecedores && fornecedores.length > 0">
                                        <option v-for="fornecedor in fornecedores" v-bind:key="fornecedor.idpessoa"
                                            :value="fornecedor.idpessoa"
                                            :selected="chamadoProgramado.fornecedor==fornecedor.idpessoa">{{fornecedor.nome}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div v-if="chamadoProgramado.manutencaoexterna" class="form-group col-md-3">
                                <div class="custom-control pl-0">
                                    <label :for="`item-garantia-${chamadoProgramado.idchamadoprogramado}`" >
                                        {{$t('sols.garantia')}}</label>
                                    <input :id="`item-garantia-${chamadoProgramado.idchamadoprogramado}`" class="form-control" type="number" min="30" v-model="chamadoProgramado.garantiaemdias">
                                </div>
                            </div>                            
                        </div>
                        <!-- Programação -->
                        <div v-if="step==2" class="form-row">
                            <chamado-programacao 
                                :id="`tarefa_${chamadoProgramado.idchamadoprogramado}`"
                                :chamado="chamadoProgramado"
                                @update="chamadoProgramado.programacao=$event"
                            ></chamado-programacao>
                        </div>
                        <!-- CheckList -->
                        <div v-if="step==3" class="form-row">
                            <div class="form-group col-md-12">
                                <label><strong>{{$t('chamadoProgramado.checklist')}}</strong></label><br>
                                <div class="form-row" v-for="cl in chamadoProgramado.checklist" :key="cl.idchamadoprogramadochecklist">
                                    <div class="form-group col-md-2" style="text-align:center;align-self:center;">
                                        {{cl.idchamadoprogramadochecklist}}
                                        <!-- <input style="text-align:center;" :value="cl.idchamadoprogramadochecklist"
                                            type="text" class="form-control" readonly
                                            :id="`checklist_${cl.idchamadoprogramadochecklist}`"> -->
                                    </div>
                                    <div class="form-group col-md-9">
                                        <input v-model="cl.descricao" @change="flagChanged(cl)"
                                            type="text" class="form-control" 
                                            :style="`${cl.ativo?'':'text-decoration:line-through;'}`"
                                            :id="`checklist_desc_${chamadoProgramado.idchamadoprogramado}_${cl.idchamadoprogramadochecklist}`">
                                    </div>
                                    <div class="form-group col-md-1" style="text-align:center;align-itens:center;">
                                        <div style="align-self:center;">
                                            <i class="ponteiro material-icons" 
                                                @click="flagDeleted(cl)"
                                            >delete</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row es-alert" id="checklist-alert">
                                    <div class="form-group col-md-12" style="text-align:center;">
                                        <div class="es-alert">{{alert}}</div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12" style="text-align:center;">
                                        <a v-on:click="newCheckList()" class="ponteiro text-white btn btn-success btn-sm mt-4">
                                            {{$t('chamadoProgramado.adicionarchecklist')}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Equipamentos -->
                        <div v-if="step==4" class="form-row">
                            <div class="form-group col-md-12">
                                <div class="form-row">
                                    <div class="col-md-1 text-center"> 
                                        <label><strong>{{$t('chamadoProgramado.ideq')}}</strong></label><br>
                                    </div>
                                    <div class="col-md-3 text-center"> 
                                        <label><strong>{{$t('chamadoProgramado.desceq')}}</strong></label><br>
                                    </div>
                                    <div class="col-md-2 text-center"> 
                                        <label><strong>{{$t('chamadoProgramado.marca')}}</strong></label><br>
                                    </div>
                                    <div class="col-md-1 text-center"> 
                                        <label><strong>{{$t('chamadoProgramado.modelo')}}</strong></label><br>
                                    </div>
                                    <div class="col-md-2 text-center"> 
                                        <label><strong>{{$t('chamadoProgramado.serie')}}</strong></label><br>
                                    </div>
                                    <div class="col-md-2 text-center"> 
                                        <label><strong>{{$t('chamadoProgramado.tombamento')}}</strong></label><br>
                                    </div>
                                    <div class="col-md-1 text-center"> 
                                        <label><strong>{{$t('global.acao')}}</strong></label><br>
                                    </div>
                                </div>
                                <div class="form-row" v-for="eq in chamadoProgramado.equipamentos" :key="eq.idchamadoprogramadoequipamento">
                                    <div class="form-group col-md-1" style="text-align:center;align-self:center;">
                                        {{eq.idequipamento}}
                                    </div>
                                    <div class="form-group col-md-3">
                                        <input v-model="eq.descricao" @change="flagEqChanged(eq)"
                                            type="text" class="form-control" 
                                            :style="`${eq.ativo?'':'text-decoration:line-through;'}`"
                                            :id="`equipamento_desc_${chamadoProgramado.idchamadoprogramado}_${eq.idchamadoprogramadoequipamento}`">
                                    </div>
                                    <div class="form-group col-md-2">
                                        <input v-model="eq.marca"
                                            type="text" class="form-control" 
                                            :style="`${eq.ativo?'':'text-decoration:line-through;'}`"
                                            :id="`equipamento_marca_${chamadoProgramado.idchamadoprogramado}_${eq.idchamadoprogramadoequipamento}`">
                                    </div>
                                    <div class="form-group col-md-1">
                                        <input v-model="eq.modelo"
                                            type="text" class="form-control" 
                                            :style="`${eq.ativo?'':'text-decoration:line-through;'}`"
                                            :id="`equipamento_modelo_${chamadoProgramado.idchamadoprogramado}_${eq.idchamadoprogramadoequipamento}`">
                                    </div>
                                    <div class="form-group col-md-2">
                                        <input v-model="eq.serie"
                                            type="text" class="form-control" 
                                            :style="`${eq.ativo?'':'text-decoration:line-through;'}`"
                                            :id="`equipamento_serie_${chamadoProgramado.idchamadoprogramado}_${eq.idchamadoprogramadoequipamento}`">
                                    </div>
                                    <div class="form-group col-md-2" style="text-align:center;align-self:center;">
                                        {{eq.tombamento}}
                                    </div>
                                    <div class="form-group col-md-1 text-center">
                                        <i class="ponteiro material-icons mt-3 hover-red" 
                                            @click="confirmarExclusao(eq)"
                                        >delete</i>
                                    </div>
                                </div>
                                <div class="form-row es-alert" id="equipamento-alert">
                                    <div class="form-group col-md-12" style="text-align:center;">
                                        <div class="es-alert">{{alert}}</div>
                                    </div>
                                </div>
                                <div class="form-row justify-content-center">
                                    <div class="form-group col-md-5 align-items-center">
                                        <select class="form-control dense" v-model="idequipamento"
                                            :id="`idequipamento_${chamadoProgramado.idchamadoprogramado}`">
                                            <template v-if="equipamentos && equipamentos.length > 0">
                                                <option v-for="eq in equipamentos" v-bind:key="eq.idequipamento"
                                                    :value="eq.idequipamento"
                                                    :selected="eq.idequipamento==idequipamento">{{`${eq.descricao} (${eq.tombamento})`}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4 align-items-center">
                                        <a v-on:click="novoEquipamento()" class="ponteiro text-white btn btn-success btn-sm">
                                            {{$t('chamadoProgramado.adicionarequipamento')}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Botões -->
                        <div class="form-row">
                            <div class="form-group col-md-auto">
                                <a v-on:click="checkAndSave()" class="ponteiro text-white btn btn-info mt-4">{{$t('global.salvar')}}</a>
                            </div>
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">{{$t('global.fechar')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalESConfirmar
                :valor="exibirModalConfirmacao"
                :texto="textoConfirmacao"
                @sim="excluirEquipamento()"
                @nao="cancelarExclusao()"
                @fechar="exibirModalConfirmacao=false"
            ></ModalESConfirmar>        
        </div>
    </div>    
</template>

<script>
import ChamadoProgramacao from './ChamadoProgramacao.vue';
import ModalESConfirmar from './ModalESConfirmar.vue';

export default {
    components: {ChamadoProgramacao, 
        ModalESConfirmar
    },
    props: {
        chamadoProgramado: {
            type: Object,
            required: true,
        },
        prioridades: {
            type: Array,
            required: false
        },
        responsaveis: {
            type: Array,
            required: true
        },
        locaisAtendimento: {
            type: Array,
            required: true
        },
        idiomas: {
            type: Array,
            required: true
        },
        setores: {
            type: Array,
            required: true
        },
        tipossolicitacao: {
            type: Array,
            required: true
        },
        fornecedores: {
            type: Array,
            required: true
        },
        equipamentos: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            tiposolicitacaoItens: [],
            tipominuto: 0, 
            minuto: null,  
            tipohora: 0, 
            hora: null,
            tipodiames: 0,
            diames: null, 
            tipomes: 0,
            mes: null,  
            tipodiasemana: 0,
            diasemana: null, 
            dias: this.$t('chamadoProgramado.dias'),
            meses: this.$t('chamadoProgramado.meses'),
            env: '',
            valid: false,
            alert: '',
            step: 1,
            idequipamento: null,
            textoConfirmacao: ``,
            exibirModalConfirmacao: false,
            equipamentoAExcluir: null
        }
    },
    watch: {        
        tipominuto(val) {
            this.minuto = val == '0' ? null : (val == '1' ? (this.minuto?this.minuto:0) : (this.minuto?this.minuto:30));
            this.chamadoProgramado.programacao = this.prog;
            this.$forceUpdate();
        },
        tipohora(val) {
            this.hora = val == '0' ? null : (val == '1' ? (this.hora?this.hora:0) : (this.hora?this.hora:1));
            this.chamadoProgramado.programacao = this.prog;
            this.$forceUpdate();
        },
        tipodiames(val) {
            this.diames = val == '0' ? null : (val == '1' ? (this.diames?this.diames:1) : (this.diames?this.diames:15));
            this.chamadoProgramado.programacao = this.prog;
            this.$forceUpdate();
        },
        tipomes(val) {
            this.mes = val == '0' ? null : (val == '1' ? (this.mes?this.mes:1) : (this.mes?this.mes:3));
            this.chamadoProgramado.programacao = this.prog;
            this.$forceUpdate();
        },
        tipodiasemana(val) {
            this.diasemana = val == '0' ? null : (this.diasemana?this.diasemana:0);
            this.chamadoProgramado.programacao = this.prog;
            this.$forceUpdate();
        }
    },
    methods: {
        changeStep(step) {
            this.step = step;
            this.$forceUpdate();
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },
        flagDeleted(cl) {
            cl.ativo = !cl.ativo;
            cl.changed = true;
            this.$forceUpdate();
        },
        flagChanged(cl) {
            cl.changed = true;
            this.fade(document.getElementById('checklist-alert'));
            this.$forceUpdate();
        },
        confirmarExclusao(eq) {
            this.textoConfirmacao = `Confirma exclusão do equipamento ${eq.descricao} (${eq.tombamento}) ?`;
            this.exibirModalConfirmacao = true;
            this.equipamentoAExcluir = eq;
        },
        excluirEquipamento() {
            if (this.equipamentoAExcluir) {
                const indice = this.chamadoProgramado.equipamentos.findIndex((x)=>x.idequipamento===this.equipamentoAExcluir.idequipamento)
                if (indice !== -1) {
                    this.chamadoProgramado.equipamentos[indice].changed = true;
                    this.chamadoProgramado.equipamentos[indice].ativo = false;
                    this.chamadoProgramado.equipamentos[indice].deleted = true;
                    this.$forceUpdate();
                }
            }
            this.exibirModalConfirmacao = false;
        },
        cancelarExclusao() {
            this.equipamentoAExcluir = null;
            this.exibirModalConfirmacao=false;
        },
        flagEqChanged(eq) {
            eq.changed = true;
            this.fade(document.getElementById('equipamento-alert'));
            this.$forceUpdate();
        },
        emptyCheckListExists() {
            let result = false;
            if (this.chamadoProgramado.checklist) {
                for (var i = 0; i < this.chamadoProgramado.checklist.length; i++) {
                    let cl = this.chamadoProgramado.checklist[i];
                    if (cl.descricao) {
                        let trimedStr = cl.descricao.trim()
                        if (!trimedStr) {
                            result = true;
                        }
                    } else {
                        result = true;
                    }
                }
            }
            //console.error(`emptyCheckListExists(): ${result}`)
            return result;
        },
        emptyEquipamentoExists() {
            //console.error(`emptyEquipamentoExists()`);
            let result = false;
            if (this.chamadoProgramado.equipamentos) {
                for (var i = 0; i < this.chamadoProgramado.equipamentos.length; i++) {
                    let eq = this.chamadoProgramado.equipamentos[i];
                    if (eq.descricao) {
                        let trimedStr = eq.descricao.trim()
                        if (!trimedStr) {
                            result = true;
                        }
                    } else {
                        result = true;
                    }
                }
            }
            return result;
        },
        newCheckList() {
            if (this.emptyCheckListExists()) {
                this.alert = 'Preencha as descrições dos checklists existentes antes de adicionar um novo.'
                this.unfade(document.getElementById('checklist-alert'));
            } else {
                this.$emit('newCheckList',this.chamadoProgramado);
            }
            this.$forceUpdate();
        },
        novoEquipamento() {
            console.error(`novoEquipamento()`)
            const indice = this.equipamentos.findIndex((x)=>x.idequipamento===this.idequipamento)
            if (indice !== -1) {
                const novoEq = Object.assign({}, this.equipamentos[indice]);
                novoEq.changed = true;
                novoEq.idchamadoprogramadoequipamento = 0;
                this.chamadoProgramado.equipamentos.push(novoEq);
                this.equipamentos.splice(indice,1);
                this.$forceUpdate();
            }
        },
        getTypeValue(str) {
            let result = {tipo: '0', valor: null}
            if (str == '*') {
                result.tipo = '0'
                result.valor = null
            } else {
                if (str.substring(0,2) == '*/') {
                    result.tipo = '2'
                    result.valor = parseInt(str.substring(2,str.length))
                } else {
                    result.tipo = '1'
                    result.valor = parseInt(str.substring(0,str.length))
                }
            }
            //console.error(`result: ${JSON.stringify(result)}`)
            return result;
        },
        checkAndSave() {
            if (this.isValid()) {
                this.chamadoProgramado.programacao = this.prog
                this.$emit('save',this.chamadoProgramado)
            } else {
                this.$snackW(`Inválido`)
            }
        },
        isValid() {
            this.valid = this.chamadoProgramado.textohotlinesolicitacoes
                && this.chamadoProgramado.idtiposolicitacao;
            return this.valid;
        },
        loadTipoSolicitacaoItens(id) {
            //console.error(`loadTipoSolicitacaoItens(id):${id}`)
            for (var i = 0; i < this.tipossolicitacao.length; i++) {
                if (this.tipossolicitacao[i]['idtiposolicitacao'] == id) {
                    //console.error('Entrou no if...')
                    this.tiposolicitacaoItens = this.tipossolicitacao[i].itens;
                    break;
                }
            }
            this.$forceUpdate();
        },
    },
    computed: {
        prog() {
            let result = '';
            // result += this.segundo != undefined ? 
            //     (this.tiposegundo=='0' ? '* ' : (this.tiposegundo == '1' ? `${this.segundo} ` : `*/${this.segundo} `)) 
            //     : '* '
            result += this.minuto != undefined ? 
                (this.tipominuto=='0' ? '0 ' : (this.tipominuto == '1' ? `${this.minuto} ` : `*/${this.minuto} `))
                : '0 '
            result += this.hora != undefined ? 
                (this.tipohora=='0' ? '* ' : (this.tipohora == '1' ? `${this.hora} ` : `*/${this.hora} `))
                : '* '
            result += this.diames != undefined ? 
                (this.tipodiames=='0' ? '* ' : (this.tipodiames == '1' ? `${this.diames} ` : `*/${this.diames} `))
                : '* '
            result += this.mes != undefined ? 
                (this.tipomes=='0' ? '* ' : (this.tipomes == '1' ? `${this.mes} ` : `*/${this.mes} `))
                : '* '
            result += this.diasemana != undefined ? 
                (this.tipodiasemana=='0' ? '*' : `${this.diasemana}`)
                : '*'

            return result;
        }
    },
    created() {
        this.env = process.env.NODE_ENV;
        //this.chamadoProgramado = {... this.chamadoProgramado};
    },
    mounted() {
        //this.chamadoProgramado = {... this.chamadoProgramado};
        //this.loadTipoSolicitacaoItens(this.chamadoProgramado.idtiposolicitacao);
    }
}
</script>

<style>
.es-azure {
    background-color: azure!important;
}
.es-icon {
	padding: 0.3em;
	font-size: 2.5em;
}
.es-col-label {
    align-self:center;
    font-weight:normal;
    font-size:1em;
    color: rgb(137, 138, 146);
}
.es-alert {
    color: orange;
	font-weight: bold;
}
.hover-red:hover {
    color: red;
}
</style>